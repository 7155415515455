import http from "../http-common";
/* eslint-disable */
class NakesService {
  nakesList(
    page,
    itemPerPage,
    keyword,
    komisariat = "",
    dpw = "",
    dpd = "",
    propinsi = "",
    kabupaten = "",
    profesi = "",
  ) {
    return http.get(
      "/nakes/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&komisariat_id=" +
        komisariat +
        "&dpw_id=" +
        dpw +
        "&dpd_id=" +
        dpd +
        "&propinsi_id=" +
        propinsi +
        "&kabupaten_id=" +
        kabupaten +
        "&profesi_id=" +
        profesi,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      },
    );
  }

  calonNakesList(page, itemPerPage, keyword, komisariat, dpw, dpd) {
    return http.get(
      "/calon-nakes/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&komisariat_id=" +
        komisariat +
        "&dpw_id=" +
        dpw +
        "&dpd_id=" +
        dpd,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      },
    );
  }

  nakesMutasiList(page, itemPerPage, keyword, komisariat) {
    return http.get(
      "/nakes-mutasi/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&komisariat_id=" +
        komisariat,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      },
    );
  }

  getUser(id) {
    return http.get(`/nakes/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  profesiList() {
    return http.get("/profesi/list", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  jenisKelaminList() {
    return http.get("/jeniskelamin/list", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  agamaList() {
    return http.get("/agama/list", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  pendidikanList() {
    return http.get("/pendidikan/list", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  statusPernikahanList() {
    return http.get("/statuspernikahan/list", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  statusPekerjaanList() {
    return http.get("/statuspekerjaan/list", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  communityList(profesiId) {
    return http.get("/community/list?profesi_id=" + profesiId, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  communityListAll() {
    return http.get("/community/list/all", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  propinsiList() {
    return http.get("/propinsi/list", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  kabupatenList(propinsiId) {
    return http.get("/kabupaten/list?propinsi_id=" + propinsiId, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  kecamatanList(kabupatenId) {
    return http.get("/kecamatan/list?kabupaten_id=" + kabupatenId, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  kelurahanList(kecamatanId) {
    return http.get("/kelurahan/list?kecamatan_id=" + kecamatanId, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  verifikasiCalonAnggota(data) {
    var bodyFormData = new FormData();

    if (data.id) bodyFormData.append("id", data.id);

    return http.post("/verifikasi-calon-nakes", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  create(data, photo) {
    var bodyFormData = new FormData();

    if (data.nira) bodyFormData.append("nira", data.nira);
    if (data.nama) bodyFormData.append("nama", data.nama);
    if (data.phone) bodyFormData.append("phone", data.phone);
    if (data.email) bodyFormData.append("email", data.email);
    if (data.str) bodyFormData.append("str", data.str);
    if (data.no_anggota) bodyFormData.append("no_anggota", data.no_anggota);
    if (data.no_ktp) bodyFormData.append("no_ktp", data.no_ktp);
    if (data.tempat_lahir)
      bodyFormData.append("tempat_lahir", data.tempat_lahir);

    if (data.tanggal_lahir)
      bodyFormData.append("tanggal_lahir", data.tanggal_lahir);

    if (data.masa_berlaku)
      bodyFormData.append("masa_berlaku", data.masa_berlaku);

    if (data.address) bodyFormData.append("address", data.address);

    if (data.selectedProfesi && data.selectedProfesi != undefined) {
      bodyFormData.append("profesi_id", data.selectedProfesi.code);
    }

    if (data.selectedJenisKelamin && data.selectedJenisKelamin != undefined) {
      bodyFormData.append("jenis_kelamin_id", data.selectedJenisKelamin.code);
    }

    if (data.selectedAgama && data.selectedAgama != undefined) {
      bodyFormData.append("agama_id", data.selectedAgama.code);
    }

    if (data.selectedPropinsi && data.selectedPropinsi != undefined) {
      bodyFormData.append("propinsi_id", data.selectedPropinsi.id);
    }

    if (data.selectedKabupaten && data.selectedKabupaten != undefined) {
      bodyFormData.append("kabupaten_id", data.selectedKabupaten.id);
    }

    if (data.selectedKecamatan && data.selectedKecamatan != undefined) {
      bodyFormData.append("kecamatan_id", data.selectedKecamatan.id);
    }

    if (data.selectedKelurahan && data.selectedKelurahan != undefined) {
      bodyFormData.append("kelurahan_id", data.selectedKelurahan.id);
    }

    if (data.selectedKomisariat && data.selectedKomisariat != undefined) {
      bodyFormData.append("komisariat_id", data.selectedKomisariat.id);
    }

    if (photo != null) bodyFormData.append("photo", photo);

    return http.post("/nakes", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(id, data, photo) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    if (data.nira) bodyFormData.append("nira", data.nira);
    if (data.nama) bodyFormData.append("nama", data.nama);
    if (data.phone) bodyFormData.append("phone", data.phone);
    if (data.email) bodyFormData.append("email", data.email);
    if (data.email_satu_sehat)
      bodyFormData.append("email_satu_sehat", data.email_satu_sehat);
    if (data.password_satu_sehat)
      bodyFormData.append("password_satu_sehat", data.password_satu_sehat);
    if (data.str) bodyFormData.append("str", data.str);
    if (data.no_anggota) bodyFormData.append("no_anggota", data.no_anggota);
    if (data.no_ktp) bodyFormData.append("no_ktp", data.no_ktp);
    if (data.tempat_lahir)
      bodyFormData.append("tempat_lahir", data.tempat_lahir);

    if (data.tanggal_lahir)
      bodyFormData.append("tanggal_lahir", data.tanggal_lahir);

    if (data.masa_berlaku)
      bodyFormData.append("masa_berlaku", data.masa_berlaku);

    if (data.address) bodyFormData.append("address", data.address);

    if (data.selectedProfesi && data.selectedProfesi != undefined) {
      bodyFormData.append("profesi_id", data.selectedProfesi.code);
    }

    if (data.selectedJenisKelamin && data.selectedJenisKelamin != undefined) {
      bodyFormData.append("jenis_kelamin_id", data.selectedJenisKelamin.code);
    }

    if (data.selectedAgama && data.selectedAgama != undefined) {
      bodyFormData.append("agama_id", data.selectedAgama.code);
    }

    if (data.selectedPropinsi && data.selectedPropinsi != undefined) {
      bodyFormData.append("propinsi_id", data.selectedPropinsi.id);
    }

    if (data.selectedKabupaten && data.selectedKabupaten != undefined) {
      bodyFormData.append("kabupaten_id", data.selectedKabupaten.id);
    }

    if (data.selectedKecamatan && data.selectedKecamatan != undefined) {
      bodyFormData.append("kecamatan_id", data.selectedKecamatan.id);
    }

    if (data.selectedKelurahan && data.selectedKelurahan != undefined) {
      bodyFormData.append("kelurahan_id", data.selectedKelurahan.id);
    }

    if (data.selectedKomisariat && data.selectedKomisariat != undefined) {
      bodyFormData.append("komisariat_id", data.selectedKomisariat.id);
    }

    if (photo != null) bodyFormData.append("photo", photo);

    return http.put(`/nakes/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/nakes/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new NakesService();
