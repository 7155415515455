<template>
  <v-app id="inspire">
    <v-navigation-drawer v-if="$route.meta.isHome === 1" v-model="drawer" app>
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-avatar color="primary" size="56">
              <v-img :src="currentUser.photo"></v-img>
            </v-avatar>
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              {{ currentUser.nama }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ currentUser.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list nav dense>
        <router-link
          v-if="getAllRole.some((r) => [1, 3, 4, 5].includes(r))"
          to="/dashboard"
          style="text-decoration: none; color: inherit"
        >
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item>
        </router-link>

        <router-link
          v-if="getAllRole.some((r) => [1, 3, 4, 5].includes(r))"
          to="/kegiatan"
          style="text-decoration: none; color: inherit"
        >
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Pelatihan</v-list-item-title>
          </v-list-item>
        </router-link>

        <v-list-group
          v-if="getAllRole.some((r) => [1, 3, 4, 5, 6].includes(r))"
          no-action
        >
          <template #activator>
            <v-list-item-icon>
              <v-icon>mdi-clock</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Membership</v-list-item-title>
            </v-list-item-content>
          </template>

          <!-- <v-list-item
            v-if="getAllRole.some((r) => [1, 3, 4, 5, 6].includes(r))"
            link
          >
            <v-list-item-content>
              <router-link
                to="/calon-anggota"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Calon Anggota</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item> -->

          <v-list-item
            v-if="getAllRole.some((r) => [1, 3, 4, 5, 6].includes(r))"
            link
          >
            <v-list-item-content>
              <router-link
                to="/nakes"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Member</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item
            v-if="getAllRole.some((r) => [1, 3, 4, 5].includes(r))"
            link
          >
            <v-list-item-content>
              <router-link
                to="/keuangan"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Keuangan</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item> -->

          <!-- <v-list-item
            v-if="getAllRole.some((r) => [1, 3, 4, 5].includes(r))"
            to="/mutasi"
            link
          >
            <v-list-item-content>
              <v-list-item-title class="text-wrap"
                >Pengajuan Mutasi</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item> -->
        </v-list-group>

        <!-- <v-list-group
          v-if="getAllRole.some((r) => [1, 3, 4, 5].includes(r))"
          no-action
        >
          <template #activator>
            <v-list-item-icon>
              <v-icon>mdi-clock</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Kelola Admin</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-if="getAllRole.some((r) => [1, 3, 4, 5].includes(r))"
            link
          >
            <v-list-item-content>
              <router-link
                to="/admin"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Admin</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="getAllRole.some((r) => [1, 3, 4, 5].includes(r))"
            link
          >
            <v-list-item-content>
              <router-link
                to="/admininfokom"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Admin Infokom</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="getAllRole.some((r) => [1, 3, 4, 5].includes(r))"
            link
          >
            <v-list-item-content>
              <router-link
                to="/admindpd"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Admin DPD</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="getAllRole.some((r) => [1, 3, 4, 5].includes(r))"
            link
          >
            <v-list-item-content>
              <router-link
                to="/admindpw"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Admin DPW</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>
        </v-list-group> -->

        <!-- <router-link
          v-if="getAllRole.some((r) => [1, 3, 4, 5].includes(r))"
          to="/dpw"
          style="text-decoration: none; color: inherit"
        >
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title>DPW</v-list-item-title>
          </v-list-item>
        </router-link> -->

        <!-- <router-link
          v-if="getAllRole.some((r) => [1, 3, 4, 5].includes(r))"
          to="/dpd"
          style="text-decoration: none; color: inherit"
        >
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title>DPD</v-list-item-title>
          </v-list-item>
        </router-link> -->

        <!-- <router-link
          v-if="getAllRole.some((r) => [1, 3, 4, 5].includes(r))"
          to="/komisariat"
          style="text-decoration: none; color: inherit"
        >
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Komisariat</v-list-item-title>
          </v-list-item>
        </router-link> -->

        <v-list-item v-if="getRoleId == 1" to="/informasi-broadcast" link>
          <v-list-item-icon>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Informasi Broadcast</v-list-item-title>
        </v-list-item>

        <v-list-group v-if="getRoleId == 1" no-action>
          <template #activator>
            <v-list-item-icon>
              <v-icon>mdi-clock</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Master</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/bank"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Bank</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/profesi"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Profesi</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/komunitas"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Komunitas</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/master/jabatanlist"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Jabatan</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-group
          v-if="getAllRole.some((r) => [1, 3, 4, 5].includes(r))"
          no-action
        >
          <template #activator>
            <v-list-item-icon>
              <v-icon>mdi-clock</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Web Content</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/sejarah"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Sejarah</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/visi-misi"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Visi Misi</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/struktur-organisasi"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Struktur Organisasi</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/fasilitator"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Fasilitator</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item> -->

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/berita"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Berita</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/testimoni"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Testimoni</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/fasilitas"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Fasilitas</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item> -->

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/pengurus"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Pengurus</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/agenda-pelatihan"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Agenda Pelatihan</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/jadwalpelatihanlist"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Jadwal Pelatihan</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item> -->

          <!-- <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/brosurlist"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Brosur</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item> -->
        </v-list-group>
        <router-link to="/" style="text-decoration: none; color: inherit">
          <v-list-item link @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </router-link>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar v-if="$route.meta.isHome === 1" color="primary" dark app dense>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>Tedika </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>

    <router-view></router-view>

    <!-- <v-footer
      padless
      color="purple"
      v-if="$route.meta.isHome === 1"
    >
      <v-col
        class="text-center white--text"
        cols="12"
      >
        {{ new Date().getFullYear() }} — <strong>Halo Medika</strong>
      </v-col>
    </v-footer> -->
  </v-app>
</template>

<script>
import store from "./store/index";

export default {
  data: () => ({
    drawer: null,
    currentUser: {
      nama: "",
      photo: "",
      email: "",
    },
  }),
  computed: {
    getRoleId() {
      return store.getters.getUser.role_id;
    },
    // getKomisariatNama() {
    //   return store.getters.getUser.komisariat_nama;
    // },
    getAllRole() {
      return store.getters.getUser.all_role_user;
    },
  },
  mounted() {
    var myUser = JSON.parse(
      localStorage.getItem(process.env.VUE_APP_USER_PREFIX),
    );

    if (myUser) {
      this.currentUser = myUser;
    }
  },

  methods: {
    logout: async function () {
      this.$store.dispatch("logout").then(() =>
        this.$router.push("/").catch((err) => {
          console.log(err);
        }),
      );
    },
  },
};
</script>
